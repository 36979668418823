<template>
  <div>
    <el-button size="mini" type="success" @click="to">编辑</el-button>
    <eForm ref="form" :sup_this="sup_this" :is-add="false" :sites="sites"/>
  </div>
</template>
<script>
import eForm from './form'
export default {
  components: { eForm },
  props: {
    data: {
      type: Object,
      required: true
    },
    sup_this: {
      type: Object,
      required: true
    },
    sites: {
      type: Array,
      required: true
    },
  },
  methods: {
    to() {
      const _this = this.$refs.form
      _this.form = {
        id: this.data.id,
        siteId: this.data.siteId,
        templateName: this.data.templateName,
        templateDesc: this.data.templateDesc,
        subject: this.data.subject,
        lang: this.data.lang,
        content: this.data.content,
        status: this.data.status.toString(),
      }
      _this.fileUrl = this.data.templateUrl;
      _this.dialog = true
    }
  }
}
</script>

<style scoped>
  div{
    display: inline-block;
    margin-right: 3px;
  }
</style>
