<template>
  <el-dialog
          :append-to-body="true"
          :visible.sync="dialog"
          v-if="dialog"
          :title="isAdd ? '新增' : '编辑'"
          width="900px">
    <el-form ref="form" :model="form" :rules="rules" size="small" label-width="120px">
      <el-form-item label="站点名称" prop="siteId">
        <el-select v-model="form.siteId" filterable placeholder="请选择" style="width: 250px;">
          <el-option
            v-for="item in sites"
            :key="item.id"
            :label="item.siteNameEn+'-'+item.siteName"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="模板名称" prop="templateName">
        <el-input v-model="form.templateName" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="语言/language" prop="lang">
        <el-select v-model="form.lang" placeholder="请选择">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="描述" prop="templateDesc">
        <el-input type="textarea" autosize v-model="form.templateDesc" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="主题" prop="subject">
        <el-input v-model="form.subject" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="状态" prop="status">
        <el-radio v-model="form.status" label=1>正常</el-radio>
        <el-radio v-model="form.status" label=0>锁定</el-radio>
      </el-form-item>
      <el-form-item
              v-if="uploadShow"
              label="上传html"
              prop="file"
      >
        <el-upload
                ref="upload"
                class="upload-demo"
                :action="filesUploadApi"
                :on-success="handleSuccess"
                :limit=1
                :show-file-list="false"
                :before-upload="beforeAvatarUpload"
                >
          <el-button size="small" type="primary">{{uploadText}}</el-button>
          <div slot="tip" class="el-upload__tip">只能上传html文件</div>
        </el-upload>
        <div class="url-span" v-if="fileUrl">
          <a class="file-link" :href="fileUrl" download>{{fileUrl}}</a>
        </div>
      </el-form-item>
       <el-form-item v-if="!fileUrl" label="内容">
        <quill-editor
                @catchData="catchData"
                :defaultValues="form.content"
        ></quill-editor>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button type="text" @click="cancel">取消</el-button>
      <el-button :loading="loading" type="primary" @click="doSubmit">确认</el-button>
    </div>
  </el-dialog>
</template>

<script>
  import { mapGetters } from "vuex"
import { add, edit } from '@/api/tools/tMailTemplate'
import quillEditor from '@/components/tools/quillEditor'
import Editor from '@/components/tools/Editor'
export default {
  components: { Editor,quillEditor },
  props: {
    isAdd: {
      type: Boolean,
      required: true
    },
    sup_this: {
      type: Object,
      default: null
    },
    sites: {
      type: Array,
      required: true
    },
  },
  data() {
    return {
      loading: false, dialog: false,
      form: {
        id: '',
        templateName: '',
        templateDesc: '',
        subject: '',
        lang: '',
        content: '',
        status: '1'
      },
      rules:{
        siteId:[
          { required: true, message: '请选择站点名字',trigger: 'change' },
        ],
        templateName:[
          { required: true, message: '请输入模板名称',trigger: 'blur' },
        ],
        lang:[
          { required: true, message: '请选择语言',trigger: 'change' },
        ],
        templateDesc:[
          { required: true, message: '请选择语言',trigger: 'blur' },
        ],
        subject:[
          { required: true, message: '请输入主题',trigger: 'blur' },
        ]
      },
      options:[
        {"value":"en_US","label":"English"},
        {"value":"zh_CN","label":"中文"},
      ],
      fileUrl: '',
      fileList: [],
    }
  },
  computed: {
    ...mapGetters(['filesUploadApi']),
    uploadText(){
      return this.isAdd ? '点击上传': '重新上传';
    },
    uploadShow(){
      if(this.isAdd){
        return true;
      }else {
        return this.fileUrl;
      }
    }
  },
  methods: {
    handleSuccess(response){
      this.fileUrl = response.data.content[0].url;
      this.$refs.upload.clearFiles();
    },
    deleteHandle(){
      this.fileName = '';
      this.fileUrl = '';
    },
    handlePreview(file) {
    },
    beforeAvatarUpload(file) {
      const isHtml = file.type === 'text/html';
      if (!isHtml) {
        this.$message.error('请上传html文件');
      }
      return isHtml;
    },
    cancel() {
      this.resetForm();
    },
    doSubmit() {
      if (this.isAdd) {
        this.doAdd()
      } else this.doEdit()
    },
    doAdd() {
      this.$refs.form.validate((valid) => {
        if(valid){
          if(!this.fileUrl &&  !this.form.content){
            this.$notify({
              title: '请上传html文件或者在富文本输入内容',
              type: 'success',
              duration: 2500
            })
            return;
          };
          this.loading = true;
          let params = {
            ...this.form
          }
          delete params.id;
          if(this.fileUrl){
            params.templateUrl = this.fileUrl;
            delete params.content;
          }
          add(params).then(res => {
            this.resetForm()
            this.$notify({
              title: '添加成功',
              type: 'success',
              duration: 2500
            })
            this.loading = false
            this.sup_this.init();
          }).catch(err => {
            this.loading = false
            console.log(err.response.data.message)
          })
        }else {
          console.log('no-pass')
        }
      });
    },
    doEdit() {
      this.$refs.form.validate((valid) => {
        this.loading = true;
        let params = {
          ...this.form,
        }
        if(this.fileUrl){
          params.templateUrl = this.fileUrl;
          delete params.content;
        }
        edit(params).then(res => {
          this.resetForm()
          this.$notify({
            title: '修改成功',
            type: 'success',
            duration: 2500
          })
          this.loading = false
          this.sup_this.init();
        }).catch(err => {
          this.loading = false
        })
      });
    },
    resetForm() {
      this.dialog = false
      this.$refs['form'].resetFields()
      this.form = {
        id: '',
        templateName: '',
        templateDesc: '',
        subject: '',
        lang:'',
        content: '',
        status: '1'
      };
      this.fileUrl = '';
    },
    catchData(value){
      //在这里接受子组件传过来的参数，赋值给data里的参数
      this.form.content=value;
    },
  }
}
</script>

<style lang="less" scoped>
.dialog-footer{
  margin-top: 40px;
}
  .url-span {
    .file-link {
      background-color: #CCCCCC;
      padding: 10px;
    }
    .el-icon-close {
      margin-left: 10px;
      font-size: 20px;
      cursor: pointer;
    }
  }
</style>
